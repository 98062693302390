:root {
	--page-color: black;
}

a,
li, p{
	font-family: "Roboto Mono", monospace;
	font-weight: 400;
	color: var(--page-color);
	text-decoration: none;
	list-style: none;
}

.four-title {
	font-family: "Roboto Mono", monospace;
	font-weight: 400;
	margin-top: 10vh;
	margin-bottom: 2vh;
	width: 100%;
	text-align: center;
	font-size: 10em;
}

.four-words {
	text-align: center;
}


@media (max-width: 1024px) {
	.four-title {
		margin-top: 6vh;
		font-size: 5em;
	}
}



